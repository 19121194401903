.time-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.time-options-container {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.time-options-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.time-options-label {
    font-size: 1rem;
}

.time-options-item input {
    font-size: 2rem;
}

.time-day {
    font-size: 1.6rem;
}