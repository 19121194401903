.opening-times-container {
    display: flex;
    flex-direction: column;
}

.opening-day-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.opening-day-dayname{
    min-width: 100px;
    font-weight: 600;
}

.opening-day-time {
    min-width: 120px;
}

@media only screen and (max-width: 600px) {
    .opening-day-container {
        margin-bottom: 5px;
    }
}