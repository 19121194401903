.google-places-autocomplete {
    min-height: 200px;
}

.google-places-autocomplete input {
    font-size: 1.6rem;
}

.google-places-autocomplete__suggestion {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .google-places-autocomplete {
        padding-left: 10px;
        padding-right: 10px;
        min-height: 280px;
    }

}