
.bottom-nav-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
}

.bottom-nav-buttons button {
    cursor: pointer;
}

.bottom-nav-next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    /* margin-right: 20px; */
    background-color: white;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: #3CA0F3;
    font-size: 1.2rem;
    border: 2px solid white;
    width: 130px;
    height: 36px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.bottom-nav-next-button:hover {
    background-color: #f0f0f0;
    border: 2px solid #f0f0f0;
    color: #3CA0F3;
}

.bottom-nav-next-button:active:hover {
    box-shadow: none;
    background-color: #e0e0e0;
    border: 2px solid #e0e0e0;
}

.bottom-nav-next-button:disabled {
    background-color: #d0d0d0;
    border-color:  #d0d0d0;
    box-shadow: none;
}

.bottom-nav-next-button:disabled:active:hover {
    box-shadow: none;
    background-color: #d0d0d0;
    border: 2px solid #d0d0d0;
}
.bottom-nav-back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    /* margin-right: 20px; */
    background-color: transparent;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;
    border: 2px solid white;
    width: 130px;
    height: 36px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.bottom-nav-back-button:hover {
    /* background-color: #f0f0f0; */
    opacity: 0.5;
    border: 2px solid #f0f0f0;
    color: white;
}

.bottom-nav-back-button:active:hover {
    box-shadow: none;
    background-color: white;
    border: 2px solid white;
    color: #3CA0F3;
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .bottom-nav-next-button {
        height: 32px;
        font-size: 1rem;
    }

    .bottom-nav-back-button {
        height: 32px;
        font-size: 1rem;
    }
}