

.home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    background-image: radial-gradient(circle at 39% 160%, #3873ff 0%, #3498db 75%, #30e6ff 110%);
    color: white;
}

@supports (-webkit-touch-callout: none) {
    .home-page {
        min-height: -webkit-fill-available;
    }
}

.home-slider-container {
    flex: 1
}

.home-topbar {
    width: 100vw;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 999;
}

.home-bottombar {
    display: flex;
    align-items: center;
    background-color: #3CA0F3;
    width: 100vw;
    height: 74px;
    z-index: 999;
}

.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    flex-direction: row !important;
}

.column {
    flex-direction: column;
}

.main-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 30px;
}

.main-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 30px;
}

.main-app-image {
  max-width:80%;
  height: auto;
}


.top-bar-logo-container {
    height: 30px;
    display: flex;
    flex-direction: 'row';
    justify-content: space-around;
}

.top-bar-logo {
    height: 100%;
    margin-left: 20px;
}

.signup-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: orange;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: #fff;
    border: 2px solid orange;
    width: 130px;
    height: 36px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.signup-button-topbar {
    margin-right: 20px;
}

.signup-button:hover {
    background-color: white;
    border: 2px solid white;
    color: #3CA0F3;
}

.signup-button:active {
    box-shadow: none;
    border: 0px;
}

.main-heading {
    text-align: center;
}

.main-message {
    font-size: 1.6rem;
    margin-bottom: 10px;
    text-align: center;
}

.bullet-title {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;
    width: 100%;
}

.bullets {
    margin-top: 10px;
    list-style-type: square;
    font-size: 1.6rem;
}

.bullets li {
    margin-bottom: 10px;
}

/* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */

.home-pricing-container {
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    background-color: white;
    box-shadow: 0 0 20px #404040;
    text-align: center;
}

.home-pricing-title {
    color: black;
    font-size: 2rem;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    /* text-transform: uppercase; */
    font-style: italic;
    font-weight: 600;
    /* color: #303030; */
    color: orange;
}

.home-pricing-item {
    color: #808080;
    text-align: center;
    font-size: 1.4rem;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    font-weight: 300;
}

.home-pricing-item-last {
    border-bottom: none;
}

.home-pricing-price {
    margin-top: 10px;
    background-color: orange;
    padding: 20px;
    padding-bottom: 20px;
}

.home-pricing-price-value {
    font-size: 1.4rem;
}

.home-pricing-price-subtext {
    font-size: 0.8rem;
}

@media only screen and (min-width: 600px) {
    .hide-full-screen {
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    .hide-mobile {
        display: none !important;
    }

    .home-topbar {
        height: 32px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .home-bottombar {
        height: 48px;
    }

    .top-bar-logo-container {
        height: 16px;
        /* display: none; */
    }

    .signup-button {
        height: 24px;
    }

    .main-left {
        display: none !important;
    }

    .main-right {
        padding: 15px;
    }

    .main-heading {
        font-size: 1.8rem;
    }

    .main-message {
        font-size: 1.4rem;
    }

    .bullet-title {
        font-size: 1.4rem;
    }

    .bullets {
        font-size: 1.4rem;
    }
}