.select-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.select-input-right-container {
    display: flex;
    width: 70%;
    flex-direction: column;
}

.select-input-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: #0f0f0f;
    border-radius: 0px;
    border-color: #eeeeee;
    border-width: 1px;
    font-size: 1.5rem;
    padding: 7px;
    /* flex: 1; */
    width: 100%;

    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
    background-position: right 5px top; /* Change the px value here to change the distance */

}

.select-input-label {
    font-size: 1.5rem;
    color: #606060;
    margin-right: 10px;
    width: 30%;
    text-align: right;
}

.select-input-hint {
    font-size: 0.9rem;
    color: #606060;
}

@media only screen and (max-width: 600px) {
    .select-input-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0px;
    }

    .select-input-label {
        text-align: left;
        font-weight: 600;
        font-size: 3vh;
    }

    .select-input-right-container {
        width: 100%;
    }

    .select-input-select {
        font-size: 3.5vh;
        padding: 4px;
    }
}