.selectstation-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    max-height: 100%;
}
.selectstation-all-stations-container {
    /* height: 50vh; */
    flex: 1;
    margin-bottom: 20px;
    /* height: 200px; */
    overflow-y: auto;
    position: relative;
}
.selectstation-station-container {
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-items: center;
    cursor: pointer;
}

.selectstation-station-container:hover {
    background-color: #f0f0f0;
}

.selectstation-station-container:hover:active {
    background-color: #e0e0e0;
}

.selectstation-station-container img {
    height: 48px;
    width: 48px;
}

.selectstation-station-details {
    margin-left: 16px;
}

.selectstation-station-name {
    font-size: 1.2rem;
    padding-bottom: 3px;
}

.selectstation-station-address {
    color: #606060;
}