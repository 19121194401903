.text-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.text-input-right-container {
    display: flex;
    width: 70%;
    flex-direction: column;
}

.text-input-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1.5rem;
    padding: 7px;
    /* flex: 1; */
    border-color: #eeeeee;
    border-style: solid;
    border-width: 1px;
    color: #0f0f0f;
    width: 100%;
}

.text-input-label {
    font-size: 1.5rem;
    color: #606060;
    margin-right: 10px;
    width: 30%;
    text-align: right;
}

.text-input-hint {
    font-size: 0.9rem;
    color: #606060;
}

@media only screen and (max-width: 600px) {

    .text-input-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0px;
    }

    .text-input-label {
        text-align: left;
        font-weight: 600;
        font-size: 3vh;
        width: initial;
    }

    .text-input-right-container {
        width: 100%;
    }

    .text-input-input {
        font-size: 3.5vh;
        padding: 4px;
    }

}