.awssld__content {
    background-color: unset;
    align-items: initial;
}

.awssld__content > div {
    /* position: relative; */
    display: flex;
    /* height: 100%; */
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}