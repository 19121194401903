.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.opening-change-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition-duration: 0.3s;
    /* border-radius: 20px; */
    color: #3CA0F3;
    font-size: 1.4rem;
    border: none;
    /* width: 180px; */
    /* height: 36px; */
}

.opening-change-button:hover {
    /* background-color: #f0f0f0; */
    opacity: 0.5;
    /* color: white; */
}

.opening-change-button:active:hover {
    box-shadow: none;
    background-color: white;
    color: #3CA0F3;
    opacity: 1;
}

.change-time-modal {
    background-color: white;
    min-width: 280px;
    /* padding-left: 30px;
    padding-right: 30px; */
}

.change-time-modal-header {
    /* width: 100%; */
    /* border-bottom: 1px solid gray; */
    font-size: 18px;
    text-align: center;
    padding: 5px;
    padding-top: 20px;
    background-color: #3CA0F3;
    color: white;
}

.change-time-modal-content {
    padding: 10px 5px;
}

.change-time-actions {
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.change-time-save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 10px;
    background-color: #3CA0F3;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: white;
    font-size: 1.2rem;
    border: none;
    width: 100px;
    height: 32px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.change-time-save-button:hover {
    opacity: 0.5;
}

.change-time-save-button:active:hover {
    opacity: 1;
}

.change-time-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 10px;
    /* background-color: #3CA0F3; */
    background-color: transparent;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: darkgray;
    font-size: 1.2rem;
    border: 2px solid darkgray;
    width: 100px;
    height: 32px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.change-time-cancel-button:hover {
    opacity: 0.5;
}

.change-time-cancel-button:active:hover {
    opacity: 1;
}
