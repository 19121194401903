.radio-input-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    margin-bottom: 10px;
}

.radio-input-label {
    font-size: 1.5rem;
    color: #606060;
    margin-right: 10px;
    width: 30%;
    text-align: right;
}

.radio-input-items-container {
    display: flex;
    /* width: 100%; */
    width: 70%;
    flex-direction: column;

}

.radio-input-item {
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    align-items: center;
    width: 100%;
}

.radio-input-item input {
    margin-right: 15px;
    /* width: 10%; */
}

.radio-input-hint {
    font-size: 0.9rem;
    color: #606060;
}

@media only screen and (max-width: 600px) {

    .radio-input-label {
        font-weight: 600;
        font-size: 3vh;
        width: initial;
    }

    .radio-input-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 0px;
    }

    .radio-input-items-container {
        flex-direction: row;
    }

    .radio-input-item label {
        font-size: 3.5vh;
    }
}