.success-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(circle at 39% 160%, #3873ff 0%, #3498db 75%, #30e6ff 110%);
    color: white;
}

@supports (-webkit-touch-callout: none) {
    .success-page {
        min-height: -webkit-fill-available;
    }
}

.success-heading {
    font-size: 3rem;
    /* margin-bottom: 30px; */
}

.success-message-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    max-width: 50%;
}

@media only screen and (max-width: 600px) {
    .success-message-container {
        max-width: 95%;
    }
}

.success-message {
    font-size: 1.4rem;
    padding: 10px;
}

.success-message a {
    color: white;
    /* text-decoration: none; */
}

.success-add-another-button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 20px;
    background-color: orange;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: #fff;
    border: 2px solid orange;
    /* width: 130px; */
    /* max-width: 50%; */
    height: 36px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    padding-left: 20px;
    padding-right: 20px;
    /* max-width: 70%; */
    align-self: center;
}

.success-add-another-button:hover {
    /* background-color: white; */
    /* border: 2px solid white; */
    /* color: white; */
    box-shadow: 0 0px 48px 0 rgba(255, 165, 0, 0.75);
}

.success-fuelservice-pump-logo {
    max-width: 30%;
    height: auto;
}

.success-fuelservice-word-logo {
    max-width: 20%;
    height: auto;
    margin-bottom: 30px;
}