.timeitem-value {
    padding: 10px;
    background-color: darkgray;
    color: white;
    font-size: 20px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeitem-seperator-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timeitem-separator {
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 20px;
    background-color: darkgray;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timeitem-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeitem-control {
    font-size: 32px;
    color: darkgray;
}