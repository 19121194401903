@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }

  html {
    height: -webkit-fill-available;
  }
}

/* Fix input and selects so they are the same width */
input, select {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}