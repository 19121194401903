.checkbox-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.checkbox-input-right-container {
    display: flex;
    /* width: 100%; */
    /* width: 70%; */
    flex: 1;
    flex-direction: column;
}

.checkbox-input-input {
    font-size: 1.5rem;
    padding: 7px;
    border-color: #eeeeee;
    border-style: solid;
    border-width: 1px;
    color: #0f0f0f;
    margin-right: 10px;
}

.checkbox-input-label {
    font-size: 1.5rem;
    color: #606060;
    /* margin-right: 10px; */
    /* width: 30%; */
    text-align: left;
}

.checkbox-input-hint {
    font-size: 0.9rem;
    color: #606060;
}

@media only screen and (max-width: 600px) {

    .checkbox-input-container {
        margin-left: 30px;
    }
}