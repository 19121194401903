.signup-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    /* background-image: radial-gradient(circle at 39% 160%, #3873ff 0%, #3498db 75%, #30e6ff 110%); */
    color: #0f0f0f;
}

@supports (-webkit-touch-callout: none) {
    .signup-page {
        min-height: -webkit-fill-available;
    }
}

.signup-slider-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.signup-topbar {
    background-color: #3CA0F3;
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 14px;
}

.signup-bottombar {
    display: flex;
    align-items: center;
    background-color: #3CA0F3;
    width: 100%;
    height: 74px;
}

.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.main-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 30px;
}

.main-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 30px;
}

.main-app-image {
    width: 100%;
    height: auto;
}


.signup-top-bar-logo-container {
    height: 30px;
    display: flex;
    flex-direction: 'row';
    justify-content: space-around;
}

.signup-top-bar-logo {
    height: 100%;
    margin-left: 20px;
}

.signup-top-bar-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 20px;
    /* background-color: orange; */
    transition-duration: 0.3s;
    border-radius: 20px;
    color: #fff;
    border: 2px solid white;
    width: 130px;
    height: 36px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}

.signup-top-bar-cancel-button:hover {
    background-color: white;
    border: 2px solid white;
    color: #3CA0F3;
}

.signup-top-bar-cancel-button:active {
    box-shadow: none;
    border: 0px;
}

.signup-main-title {
    text-align: center;
    font-size: 2rem;
    padding: 20px;
}

.signup-main-text {
    margin-top: -10px;
    padding-bottom: 20px;
    font-size: 1.4rem;
    margin-bottom: 10px;
    text-align: center;
}

.signup-center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.checkout-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    /* margin-right: 20px; */
    background-color: orange;
    transition-duration: 0.3s;
    border-radius: 20px;
    color: #fff;
    border: 2px solid orange;
    width: 130px;
    height: 36px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.checkout-button:hover {
    /* background-color: white; */
    /* border: 2px solid white; */
    /* color: white; */
    box-shadow: 0 0px 48px 0 rgba(255, 165, 0, 0.75);
}

.checkout-button:active {
    box-shadow: none;
    border: 0px;
    background-color: darkorange;
}

.signup-payment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 56px;
}

.signup-summary-container {

}
.signup-summary-row {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
}

.signup-summary-label {
    width: 100px;
    font-weight: 600;
}

.signup-summary-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
}

.signup-summary-time-day {
    width: 100px;
    font-weight: 500;
    text-align: center;
}

.signup-summary-error {
    color: red;
    font-weight: 600;
}

/* .signup-summary-time-time {

} */

/* .signup-summary-fuel-item {
    margin-right: 10px;
} */

.signup-summary-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: #404040;
}

@media only screen and (max-width: 600px) {

    .signup-page-contents-container {
        width: 100%;
    }

    .signup-topbar {
        height: 48px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .signup-top-bar-cancel-button {
        border: none;
        box-shadow: none;
        width: 50px;
    }

    .signup-bottombar {
        height: 48px;
    }

    .signup-main-title {
        font-size: 5vh;
        padding-left: 10px;
        padding-right: 10px;
    }

    .signup-main-text {
        font-size: 3.5vh;
        padding-left: 10px;
        padding-right: 10px;
    }

    .selectstation-all-stations-container {
        margin-bottom: 0px;
    }

    .bottom-nav-back-button {
        width: 100px;
    }

    .bottom-nav-next-button {
        width: 100px;
    }

    .signup-summary-row {
        flex-direction: column;
    }

    .signup-summary-label {
        font-weight: 600;
    }

    .signup-summary-item {
        flex-direction: column;
        margin-bottom: 7px;
    }

    .signup-summary-time-item {
        display: flex;
        flex-direction: row;
    }

    .signup-summary-container {
        flex: 1;
        overflow-y: auto;
        width: 100%;
        padding-left: 20px;
    }

    .signup-payment-container {
        border-bottom: 1px solid #E0E0E0;
        width: 100%;
    }

}